<template>
  <form class="container" v-if="!loading">
    <div class="text">
      <p>Campos avançados</p>
      <span>
        Personalize sua importação preenchendo os campos opcionais de vendedor, formulário, tag e CRM para melhor categorizar e acompanhar seus leads.
      </span>
    </div>
    <div class="container-form">
      <div class="seller" >
        <label for="seller">Vendedor <span>(Opcional)</span></label>
        <BaseSelect
          :selectModel="selectedSeller"
          placeholder="Selecione um vendedor"
          trackname="email"
          track-by="id"
          :array="sellers"
          :multiple="false"
          :taggable="true"
          class=""
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          id="seller"
          @callback="getVendedor($event)"
          @change="selectedSeller = $event"
        >
          <span slot="noResult">
            Nenhum dado encontrado.
          </span>
        </BaseSelect>
      </div>
      <div class="registration-form">
        <label for="registration-form">Formulário de inscrição <span>(Opcional)</span></label>
        <BaseSelect
          :selectModel="selectedForm"
          placeholder="Selecione o formulário"
          track-by="id"
          :array="forms"
          :multiple="false"
          :searchable="true"
          :taggable="true"
          class=""
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          id="registration-form"
          @search="debounce($event)"
          @callback="getForms($event)"
          @change="selectedForm = $event"
          :loading="loading_select"
        >
          <span slot="noResult">
            Nenhum formulário encontrado.
          </span>
        </BaseSelect>
      </div>
      <div class="crm" >
        <label for="crm">CRM <span>(Opcional)</span></label>
        <BaseSelect
        :selectModel="selectedPipeline"
          placeholder="Selecione um CRM"
          track-by="id"
          :array="pipelines"
          :multiple="false"
          :searchable="true"
          :taggable="true"
          class=""
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          id="ajax"
          @select="selectedPipe"
          @search="debouncePipeline($event)"
          @callback="getPipelines($event)"
          @change="selectedPipeline = $event"
          @remove="selectedBoard = []"
          :loading="loading_select"
        >
          <span slot="noResult">
            Nenhum CRM encontrado.
          </span>
        </BaseSelect>
      </div>
      <div class="board" >
        <label for="board">Board <span>(Opcional)</span></label>
        <multiselect
          v-model="selectedBoard"
          id="ajax"
          label="name"
          :disabled="!selectedPipeline"
          track-by="id"
          placeholder="Selecione um board"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          open-direction="bottom"
          :options="boards"
          :multiple="false"
          :searchable="true"
          :internal-search="true"
          :clear-on-select="false"
          :close-on-select="true"
          :show-no-results="true"
          :hide-selected="true"
        >
          <span slot="noOptions">Pesquise pelo board</span>

          <span slot="noResult"
            >Oops! Nenhum board encontrado.</span
          >
        </multiselect>
      </div>
      <div class="tags" >
        <label >Tag <span>(Opcional)</span></label>
        <BaseSelect
          :selectModel="selectedTags"
          placeholder="Selecione tag"
          specificType="tags"
          track-by="id"
          trackname="name"
          :array="optionsTag"
          :multiple="true"
          :searchable="true"
          :taggable="true"
          class=""
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          id="tag"
          @search="debounceTags($event)"
          @callback="getTags($event)"
          @change="selectedTags = $event"
          :loading="loading_select"
        >
          <span slot="noResult">
            Nenhum dado encontrado.
          </span>
        </BaseSelect>
      </div>
      <div class="email-notify" >
        <label for="email" >E-mail para notificação <span>(Opcional)</span></label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Digite seu e-mail"
          v-model="email"
        />
      </div>
    </div>
    <div class="container-buttons">
      <BaseButton
        variant="link-info"
        style="width: 150px;"
        @click="cancel"
      >
        Fechar
      </BaseButton>
      <BaseButton
        style="width: 150px;"
        @click="onSubmit"
        variant="primary"
        :disabled="loading"
      >
        Avançar
      </BaseButton>
    </div>
  </form>
  <div v-else class="d-flex justify-content-center" style="display: flex; align-items: center; justify-content: center; min-height: 500px" >
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";
import Multiselect, { multiselectMixin } from "vue-multiselect";

import ImportService from "@/services/resources/ImportService";
const serviceImport = ImportService.build();

import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
//
import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();
//
import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import _ from 'lodash';
export default {
  props: ["lista", "file"],
  components: {
    BaseButton,
    Multiselect,
  },  
  data(){
    return{
      pipelines: [],
      selectedPipeline: null,
      boards: [],
      selectedBoard: null,
      stops: [],
      selects: [],
      sellers: [],
      selectedSeller: null,
      forms: [],
      selectedForm: null,
      tags: [],
      selectedTags: [],
      search: "",
      email: null,
      loading_select: false,
      antiDuplicate: "",
      optionsTag: [],
      loading: false,
      historicTags: []
    }
  },
  computed: {
    fetchTags() {
      return this.$store.getters.tags;
    },
  },
  methods: {
    cancel(){
      this.$emit('cancel')
    },
    fetchImport(formData) {
      this.loading = true;
      serviceImport
        .create(formData)
        .then((resp) => {
          this.$emit('submit', resp)
        })
        .catch (error => {
          const errorMessage = error.response?.data?.error;
          if(!errorMessage){
            return;
          }
          this.$grToast.toast(errorMessage, {
            title: "Importação de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$grModal.hide("importar-dados");
          })
        .finally(() => {
          this.loading = false;
        })
    },
    onSubmit() {
      var tags = [];
      if (this.selectedTags) {
        for (let i = 0; i < this.selectedTags.length; i++) {
          tags.push(this.selectedTags[i].id);
        }
      } else {
        tags = "";
      }
      if ((this.selectedPipeline && this.selectedPipeline.id) && !(this.selectedBoard && this.selectedBoard.id)) {
        this.$grToast.toast("Por favor, selecione um board", {
          title: "Campos avançados",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      var formData = new FormData();
      formData.append("file", this.file);
      formData.append("title", this.lista);
      formData.append("tag_ids", tags);
      formData.append(
        "form_id",
        this.selectedForm && this.selectedForm.id ? this.selectedForm.id : null
      );
      formData.append("contact_address", this.email);
      formData.append(
        "seller_id",
        this.selectedSeller && this.selectedSeller.id
          ? this.selectedSeller.id
          : null
      );
      formData.append(
        "crm_id", 
        this.selectedPipeline && this.selectedPipeline.id
          ? this.selectedPipeline.id
          : null     
      );
      formData.append(
        "board.id",
        this.selectedBoard && this.selectedBoard.id
          ? this.selectedBoard.id
          : null
      );
      this.fetchImport(formData);
    },
    getForms(page = 1, query = " ") {
      if (this.stops.includes("form") && query == " ") {
        return;
      }
      var data = {
        id: `?page=${page}&order_by=title&status=active&order=ASC&search=${
          query != "" ? this.search : ""
        }`,
      };
      if (this.antiDuplicate === data.id) {
        return;
      }
      this.antiDuplicate = data.id;
      serviceForm.read(data).then((resp) => {
        this.loading_select = false;
        if (resp.data.length < resp.per_page) {
          this.stops.push("form");
        }
        this.forms = this.forms.concat(resp.data);
      });
    },
    debounce: _.debounce(function (query) {
      this.search = query;
      this.loading_select = true;
      this.forms = [];
      this.getForms(1, query);
    }, 500),
    getVendedor(page = 1) {
      if (this.stops.indexOf("seller") != -1) {
        return;
      }
      var data = {
        page: page,
      };
      serviceSeller
        .search(data)
        .then((resp) => {
          let notSeller = {
            id: 0,
            email: "Sem vendedor",
          };
          if (resp.data.length < resp.per_page) {
            this.stops.push("seller");
          }
          this.sellers = this.sellers.concat(resp.data);
        })
    },
    getPipelines(page = 1, query = ""){
      if (this.stops.includes("selectedPipeline") && query == " ") {
        return;
      }
      var data = { 
        page: `${page}&order_by=title&order=ASC&search=${query}`
      }
      if (this.antiDuplicate === data.page) {
        return;
      }
      if (page === 1) {
        this.pipelines = [];
      }
      this.antiDuplicate = data.page;
      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));
          if (response.data.length < response.per_page) {
            this.stops.push("selectedPipeline");
          }
          this.pipelines = this.pipelines.concat(pipelines);
        })
        .finally(() => {this.loading_select = false})
    },
    debouncePipeline: _.debounce(function (query) {
      this.search = query;
      this.loading_select = true;
      this.pipelines = [];
      this.getPipelines(1, query);
    }, 500),
    selectedPipe(e) {
      this.boards = [];
      this.selectedBoard = [];
      if (e.id != null) {
        var selectedTags = e.tags;
        for (let i = 0; i < selectedTags.length; i++) {
          const element = selectedTags[i];
          if (this.historicTags.find((tag) => tag.id == element)) {
            this.boards.push(
              this.historicTags.find((tag) => tag.id == element)
            );
          } else {
            serviceTags
              .read(`/show/${element}`)
              .then((resp) => {
                this.historicTags.push(resp);
                this.boards.push(resp);
              });
          }
        }
      }
    },
    getTags(page = 1, query = ""){
      if (this.stops.includes("selectedTags") && query == " ") {
        return;
      }
      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };
      serviceTags
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stops.push('selectedTags');
          }
          this.optionsTag = this.optionsTag.concat(resp.data);
          this.optionsTag = this.optionsTag.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .finally(() => {
          this.loading_select = false;
        });
    },
    debounceTags: _.debounce(function (query) {
      this.search = query
      this.loading_select = true
      this.tags = []
      this.getTags(1, query)
    }, 500),

  },
  mounted(){
    this.selectedSeller = null;
    this.selectedForm = null;
    this.tags = this.fetchTags;
    this.selectedTags = null;
    this.email = null;
    this.selectedPipeline = null,
    this.selectedBoard = null,
    this.additional = [];
    this.selected = [];
  }
}

</script>
<style lang="scss" scoped>
.container {
  display: grid;
  gap: 35px;
  .text {
    display: grid;
    gap: 10px;
    p {
      font-weight: 600;
      font-size: 18px;
      color: var(--gray01);
    }
    span {
      font-weight: 400;
      font-size: 15px;
      color: #81858E;
    }
  }
  .container-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  .container-form {
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    gap: 25px;
    label {
      span {
        color:#999E9D;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .email-notify{
      input{
        width: 100%
      }
    }
  }
}
</style>